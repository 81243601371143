import React from "react";
import "./index.css";

const WhatsAppButton = ({ phoneNumber }) => {
  const handleClick = () => {
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  return (
    <div className="whatsapp-button-container">
      <span className="whatsapp-label">Contáctanos</span>
      <button
        onClick={handleClick}
        className="whatsapp-button"
        aria-label="Chat on WhatsApp"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width="24"
          height="24"
          fill="white"
        >
          <path d="M16.003 2.004C8.832 2.004 3.007 7.83 3.007 15c0 2.652.805 5.11 2.18 7.16L3 29l7.074-2.164A12.919 12.919 0 0 0 16 28c7.17 0 13-5.827 13-13s-5.83-12.996-12.997-12.996zm.002 23.727c-2.14 0-4.23-.633-6.002-1.828l-.43-.273-4.2 1.287 1.332-4.084-.28-.443a10.742 10.742 0 0 1-1.674-5.781c0-5.948 4.845-10.791 10.793-10.791 5.946 0 10.79 4.843 10.79 10.791 0 5.945-4.844 10.788-10.79 10.788zm5.94-8.068c-.325-.162-1.922-.945-2.222-1.051-.297-.108-.514-.162-.73.162-.217.324-.837 1.052-1.025 1.268-.19.217-.375.243-.7.081-.327-.162-1.38-.508-2.63-1.617-.972-.865-1.63-1.937-1.822-2.264-.19-.324-.02-.499.143-.66.146-.145.327-.379.49-.568.162-.19.217-.324.325-.54.108-.217.054-.406-.027-.568-.082-.162-.73-1.77-1-2.429-.27-.65-.542-.56-.73-.57l-.622-.01c-.217 0-.568.08-.864.379s-1.134 1.107-1.134 2.706c0 1.598 1.162 3.143 1.325 3.361.162.216 2.29 3.5 5.54 4.908.775.334 1.38.534 1.852.684.777.247 1.485.212 2.045.129.623-.093 1.922-.783 2.194-1.54.271-.757.271-1.406.19-1.54-.082-.135-.297-.216-.622-.379z" />
        </svg>
      </button>
    </div>
  );
};

export default WhatsAppButton;
