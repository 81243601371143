const es = {
  nav_bar: {
    who_are_we: "NOSOTROS",
    what_do_we_do: "QUÉ HACEMOS",
    clients: "CLIENTES",
    procurement: "SUPPLY CHAIN MANAGEMENT",
    contact_us: "CONTACTO",
    language: "IDIOMA",
  },
  carousel: {
    development: "En un mundo totalmente conectado",
    offices: "nos esforzamos",
    quality: "para entregar lo que requiere",
    commitment: "con responsabilidad y compromiso",
  },
  who_are_we: {
    title: "Nosotros",
    desc: 'Desde <span class="font-weight-bold azul">2006</span> gestionamos en forma estratégica todas las etapas de la cadena de abastecimiento para entregar a nuestros clientes los productos en los que confían cada día, asesorándolos en sus requerimientos y guiándolos para una correcta ejecución de sus planes.',
    desc2:
      'Durante <span class="font-weight-bold azul">2009</span> nos consolidamos como un <span class="font-weight-bold azul">Operador Global</span> en la cadena de Abastecimiento desde nuestros inicios como oficina de compras en la RP China, extendiendo nuestras operaciones hacia distintos países de la región Asia Pacífico.',
    desc3:
      ' A mediados de <span class="font-weight-bold azul">2014</span> desarrollamos y lanzamos nuestra primera marca, con el compromiso definitivo de calidad e innovación hacia nuestros clientes.',

    staff:
      'Somos un <span class="font-weight-bold azul">staff multidisciplinario y multicultural</span> con vasta experiencia, recursos adecuados, especialmente ética y transparencia para ejecutar sus ideas y requerimientos.',
    offices:
      'Desde nuestras oficinas en  <span class="font-weight-bold azul">Asia y América Latina</span> podemos colaborar de manera eficiente y efectiva en todo momento a nuestros clientes y proveedores los 365 días del año. ',
    commitment:
      'Somos un equipo  <span class="font-weight-bold azul">apasionado y comprometido</span> con nuestro trabajo, creemos que la honestidad, integridad, responsabilidad y proactividad, nuestros pilares fundamentales, son la base para asesorar a nuestros clientes.',
    statistics: {
      years: "Años de trayectoria",
      cases: "Casos de exito",
      clients: "% de clientes recurrentes",
      more: "Más de",
      countries: "Países",
    },
  },
  what_do_we_do: {
    title: "Qué hacemos",
    catering: {
      title: "Gestión de Abastecimiento",
      desc: 'Un buen proyecto se funda básicamente en la solidez de sus procesos y procedimientos; desde planificar la búsqueda, localizar potenciales proveedores, estudiar, analizar y seleccionar las alternativas y costos. <span class="font-weight-bold azul">PRYME CORP</span> es su aliado para tercerizar todos estos procesos. Puede contar con nuestra <span class="font-weight-bold azul">experiencia y eficiencia</span> para tomar las mejores decisiones en el desarrollo de su proyecto, hacer que sea escalable y sustentable en el tiempo.',
    },
    logistic: {
      title: "Gestión de Desarrollo y Aseguramiento",
      desc: 'La creación y desarrollo de un producto siguiendo las directrices y especificaciones correctas son primordiales para el éxito. <span class="font-weight-bold azul">El éxito de PRYME CORP</span> se basa en comprender e implementar especificaciones y procedimientos para que cada desarrollo sea alcanzado cumpliendo todas las expectativas de nuestros clientes, desde el modelamiento de muestras, testeos, control al inicio, durante y al finalizar todo proceso productivo.',
    },
    commercial: {
      title: "Gestión Logística",
      desc: 'La entrega de productos en los plazos acordados es fundamental para rentabilizar su negocio. <span class="font-weight-bold azul">PRYME CORP opera como su socio 3PL</span> a través de nuestra red global de asociados para distribuir eficientemente sus productos “puerta a puerta” donde Ud requiera, con la flexibilidad necesaria para consolidar distintos tipos de carga, maquila y packing para entrega directa en puntos de venta (POS). Gestionamos la <span class="font-weight-bold azul">documentación</span> necesaria para que su proceso de importación sea rápido y expedito.',
    },
  },
  clients: {
    title: "Clientes",
    desc: 'En PRYME CORP estamos orgullosos de ser los creadores de <span class="font-weight-bold azul">2 fascinantes y novedosas marcas</span>:',
    desc2:
      ' Algunas marcas de renombre a las que <span class="font-weight-bold azul">apoyamos y hemos apoyado </span>son :',
    trx: "Soluciones en Cielo Raso durables y resistentes.",
    elux: "Elegancia y diseño en iluminación de nueva generación.",
  },
  management: {
    title:
      "Nuestro proceso de trabajo se traduce en lo que denominamos GESTIÓN INTEGRAL",
    client: "Cliente",
    requirements: "Especificación de requerimientos",
    design: "Diseño de producto",
    selection: "Selección de productores",
    terms_and_conditions: "Contratos y Condiciones",
    development: "Desarrolllo de producto",
    control: "Control producción",
    delivery: "Logística y Entrega",
    image: "/public/nombre-de-la-foto.jpg",
  },
  contact: {
    title: "Contáctenos",
    name: "Nombre *",
    last_name: "Apellido *",
    email: "Email *",
    country: "País *",
    desc: "¿Cómo podemos ayudarle?",
  },
  social: {
    title: "Social",
  },
  cta: {
    go_to_contact: "CONTÁCTENOS PARA AYUDARLO",
    go_to_contact2: "CONTÁCTENOS AQUÍ",
    submit: "LISTO PARA ENVIAR",
  },
  offices: {
    china: {
      name: "R. P. China",
      address1:
        "Room 624, Sen Ben Plaza, 567 TianYaoQiao Road, Xuhui District, 200030, Shanghai, P.R China",
      address2:
        "中国大陆地址: 上海市徐汇区天钥桥路567号森本大楼624室。邮编：200030",
    },
    bolivia: {
      name: "Bolivia",
      address1: "Calle Ecuador 191, Zona UAGRM",
      address2: "Santa Cruz de la Sierra",
    },
    hong_kong: {
      name: "Hong Kong (S.A.R China)",
      address1: "22Fl, 3 Lockhart Road, Wanchai, Hong Kong S.A.R",
    },
    chile: {
      name: "Chile",
      address1:
        "Av Holanda 099, Of. 1101, Providencia, 7510006, Santiago, Chile",
    },
    japon: {
      name: "Japón",
      address1: "76-6, Mameguchidai, Naka-ku, Yokohama, 231-0838, Japan",
      address2: "横浜市中区豆口台76-6",
    },
    phone: "Teléfono",
    email: "Email",
  },
};

export default es;
