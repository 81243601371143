const en = {
  nav_bar: {
    who_are_we: "ABOUT US",
    what_do_we_do: "WHAT WE DO ",
    clients: "CLIENTS",
    procurement: "SUPPLY CHAIN MANAGEMENT",
    contact_us: "CONTACT US",
    language: "LANGUAGE",
  },
  carousel: {
    development: "In a wholly connected world",
    offices: "We strive ourselves",
    quality: "To deliver what you need",
    commitment: "Responsibly and committed",
  },
  who_are_we: {
    title: "About us",
    desc: 'Since <span class="font-weight-bold azul">2006</span> we strategically manage the supply chain to deliver our customers the goods they rely on every single day, advising on their requirements and guiding them for proper execution of their plans.',
    desc2:
      'During <span class="font-weight-bold azul">2009</span>, we emerge as a global supply chain player from our beginnings as a purchasing office at P.R of China, expanding our operations across almost all countries comprising the Asia Pacific region. ',
    desc3:
      'In <span class="font-weight-bold azul">2014</span> , we develop and launch our first brand totally devoted to deliver a high-quality experience and innovation to our customers.',

    staff:
      ' We are a <span class="font-weight-bold azul">cross-functional and multicultural staff </span> with vast experience, proper resources and most relevant, an unparalleled level of ethics and transparency to execute all your ideas and requirements.',
    offices:
      ' From our locations in <span class="font-weight-bold azul">Asia and Latin America</span>, we can collaborate efficiently and effectively anytime with <span class="font-weight-bold azul">our customers and suppliers</span>  365 days a year.',
    commitment:
      'We are a passionate and <span class="font-weight-bold azul">committed to our work</span> , we believe honesty, integrity, responsibility and proactivity, our cornerstones, become the only way to support and guide our customers.',
    statistics: {
      years: "Years in the market",
      cases: "Successful experiences",
      clients: "% returning costumers",
      more: "More than ",
      countries: "Countries",
    },
  },
  what_do_we_do: {
    title: "What we do",
    catering: {
      title: "Sourcing management",
      desc: ' Any succesful project is based on solid processes and procedures, from planning to scouting and locating the right potential suppliers by studying, analyzing, selecting the best alternatives and its costs. <span class="font-weight-bold azul"> PRYME CORP </span> is your right partner for outsourcing all this processes, you can rely on us anytime, our expertise and efficiency will lead you to take your bests decisions starting your project, make it scalable and sustainable across time!',
    },
    logistic: {
      title: "Product Development & Compliance Management",
      desc: 'Any product making and development under its correct specifications and guidelines is vital to succeed. <span class="font-weight-bold azul"> PRYME CORP’s success</span>  is based in understanding and deploying the right specifications and procedures so each development strictly achieves our customer’s expectations, from sampling modeling, specialized testing to controlling and monitoring compliance before, during and after any production process.',
    },
    commercial: {
      title: "Logistics Management",
      desc: 'Logistics and delivery within proper timing basically means business profitability. <span class="font-weight-bold azul"> PRYME CORP operates as your 3PL</span> partner across our global network and this allow us to deliver your products efficiently ¨door to door¨, with extra flexibility to storage and consolidate different types of cargo, products assembly and packing delivering directly to Point of Sales (POS) finally handling all <span class="font-weight-bold azul">documents</span> required so you can smoothly and quickly proceed to import at your destination.',
    },
  },
  clients: {
    title: "Clients",
    desc: 'At PRYME CORP we proudly developed <span class="font-weight-bold azul">2 exciting and original brands</span>:',
    desc2:
      'Some renowned brands we <span class="font-weight-bold azul">supported and support</span> nowadays:',
    trx: "Enduring and resistant Panel Ceiling solutions",
    elux: "Stylish and elegant new generation lightning ",
  },
  management: {
    title: "Our workflow unfolds into what we call end-to-end services",
    client: "Client",
    requirements: "Product specification",
    design: "Product design",
    selection: "Supplier selection",
    terms_and_conditions: "Contracts and conditions",
    development: "Product development",
    control: "Production control",
    delivery: "Logistics and Delivery",
  },
  contact: {
    title: "Contact us",
    name: "Name *",
    last_name: "Last name *",
    email: "Email *",
    country: "Country *",
    desc: "How can we help you?",
  },
  social: {
    title: "Social",
    challenge: "DO YOU HAVE A CHALLENGE FOR US?",
  },
  cta: {
    go_to_contact: "CONTACT US",
    go_to_contact2: "CONTACT US HERE",
    submit: "SUBMIT",
  },
  offices: {
    china: {
      name: "P. R. China",
      address1:
        "Room 624, Sen Ben Plaza, 567 TianYaoQiao Road, Xuhui District, 200030, Shanghai, P.R China",
      address2:
        "中国大陆地址: 上海市徐汇区天钥桥路567号森本大楼624室。邮编：200030",
    },
    bolivia: {
      name: "Bolivia",
      address1: "Calle Ecuador 191, Zona UAGRM",
      address2: "Santa Cruz de la Sierra",
    },
    hong_kong: {
      name: "Hong Kong (S.A.R China)",
      address1: "22Fl, 3 Lockhart Road, Wanchai, Hong Kong S.A.R",
    },
    chile: {
      name: "Chile",
      address1:
        "Av Holanda 099, Of. 1101, Providencia, 7510006, Santiago, Chile",
    },
    japon: {
      name: "Japan",
      address1: "76-6, Mameguchidai, Naka-ku, Yokohama, 231-0838, Japan",
      address2: "横浜市中区豆口台76-6",
    },
    phone: "Phone",
    email: "Email",
  },
};

export default en;
